import './App.css';
import Table from 'react-bootstrap/Table';

function App() {

  return (
    <div className="App">

      <header className="App-header">
      <p>
         <b><h1> V1 Driving School </h1></b> 
         WhatsApp  /  Text  /  Call : 0478 568 259
         <br></br>
         <br></br>
         VicRoads Drivng Test : Werribee, Deer Park
         <br></br>
         <br></br>
         Service Suburbs : Manor Lakes, Wyndham Vale, Werribee
      </p>
         
        <Table class="packages-table">

        <colgroup>
          <col class="Mins50"></col>  
          <col class="Mins60"></col>
          <col class="Mins90"></col>
        </colgroup>

        <thead>
          <tr>
            <th>50 Minutes</th>
            <th>60 Minutes</th>
            <th>90 Minutes</th>
          </tr>
        </thead>
          
        <tbody>
            <tr>
              <td>1 Lesson &#8211; $50</td>  
              <td>1 Lesson &#8211; $60</td>
              <td>1 Lesson &#8211; $85</td>
            </tr>
            <tr>
              <td>5 Lessons &#8211; $240</td>      
              <td>5 Lessons &#8211; $280</td>
              <td>5 Lessons &#8211; $400</td>
            </tr>
            <tr>
              <td>10 Lessons &#8211; $475</td>
              <td>10 Lessons &#8211; $540</td>
              <td>10 Lessons &#8211; $770</td>
            </tr>
        </tbody>
      </Table>

      {/* <b>Driving Test Packages</b> */}
      <h4>Driving Test Packages</h4>
  
      <Table class="testpackages-table">

        <colgroup>
          <col class="Mins50"></col>  
          <col class="Mins60"></col>
          <col class="Mins90"></col>
        </colgroup>

               
        <tbody>
   
            <tr>
              <td></td>
              <td> Only Drive Test &#160;&#8211;&#160; $140</td>
              <td></td>         
            </tr>
            <tr>
              <td>1 Lesson + Test &#8211; $185</td>  
              <td>1 Lesson + Test &#8211; $190</td>
              <td>1 Lesson + Test &#8211; $210</td>
            </tr>
            <tr>
              <td>5 Lessons + Test &#8211; $360</td>
              <td>5 Lessons + Test &#8211; $400</td>
              <td>5 Lessons + Test &#8211; $515</td>
            </tr>
            <tr>
              <td>10 Lessons + Test &#8211; $585</td>
              <td>10 Lessons + Test &#8211; $640</td>
              <td>10 Lessons + Test &#8211; $870</td>
            </tr>
          </tbody>
      </Table>
	  
        <br></br>
        We charge per session and need not pay full payment for packages in advance.
		<br></br>
		Text / contact us for custom sessions, packages 

    </header>
  </div>        
  );
}

export default App;
